<template>
  <div>
    <div class="presentation__wrapper" ref="mapContRef">
      <agent-info class="regular-info" :isScoped="isAgentScoped" :isOwner="PresentationData" />
      <header class="presentation__header">
        <div class="presentation__header-map">
          <styled-map
            v-if="PresentationData.agent && mapContRef"
            :mapHeight="
              mapContRef.clientWidth > 1279
                ? 264
                : mapContRef.clientWidth < 1279
                ? 160
                : mapContRef.clientWidth < 767
                ? 120
                : 264
            "
            :mapWidth="mapContRef.clientWidth"
            :mapCenter="PresentationData.agent.city"
          />
        </div>
        <div class="agent-shadow"></div>
        <div class="title-wrapper">
          <div class="presentation__header-title">
            <h3>
              {{
                `Подобранные варианты (${PStore.$state.totalObjs ? PStore.$state.totalObjs : 0})`
              }}
            </h3>
            <p>Вторичка · до 20 млн</p>
          </div>
          <nav class="presentation__header-nav">
            <button class="switch-butt" disabled>
              <base-icon name="mapwhite" size="20px" color="var(--White)" />
              Карта
            </button>
            <button class="link-butt" @click="copyToClipboard(true)">
              <base-icon name="link" size="20px" color="var(--White)" />
              <q-tooltip
                anchor="top middle"
                self="bottom middle"
                :target="isCopiedTop"
                v-model="isCopiedTop"
                >Copied</q-tooltip
              >
            </button>
            <button class="link-butt" @click="savePdf">
              <base-icon name="downloadwhite" size="20px" color="var(--White)" />
            </button>
          </nav>
        </div>
      </header>
      <agent-mobil-info class="mobil-info" :isOwner="PresentationData" />
      <agent-sticky-header :isScoped="isAgentScoped" />
      <slot></slot>
      <button v-ripple class="modil-switch-butt">
        <base-icon name="map" size="20px" color="#2C2933" />
        На карте
      </button>
      <footer class="presentation__footer">
        <p>Подобранные варианты</p>
        <div class="presentation__footer-butts">
          <button @click="copyToClipboard(false)">
            <base-icon name="copy" size="20px" color="var(--violet-400)" />
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :target="isCopiedBot"
              v-model="isCopiedBot"
              >Copied</q-tooltip
            >Ссылка
          </button>
          <button>
            <base-icon @click="savePdf" name="download" size="20px" color="var(--violet-400)" />
            Скачать
          </button>
        </div>
      </footer>
      <!-- <pop-up /> -->
    </div>
  </div>
  <down-up-pop-up />
</template>

<script setup lang="ts">
import { PresentationStore } from '~/store/PresentationStore'
import { debounce } from 'lodash'
import { useFormStore } from '~/store/user'
import { PopUpStore } from '~/store/popUp'
import 'animate.css'

const PStore = PresentationStore()
const userStore = useFormStore()
const storePopUp = PopUpStore()

const route = useRoute()

const isCopiedTop = ref(false)
const isCopiedBot = ref(false)
const isAgentScoped = ref(false)
const mapContRef = ref()
const handleSavePdf = ref(false)

const PresentationData = computed(() => PStore.$state.tableObjsData)

const savePdf = debounce(() => {
  storePopUp.callPopUp('PdfPopUp', { opts: { name: 'allObjs' } }, true)
  handleSavePdf.value = true
  setTimeout(() => {
    handleSavePdf.value = false
  }, 500)
}, 500)

const copyToClipboard = debounce(async (c) => {
  try {
    await navigator.clipboard.writeText(window.location.href)
    c ? (isCopiedTop.value = true) : (isCopiedBot.value = true)
    setTimeout(() => {
      c ? (isCopiedTop.value = false) : (isCopiedBot.value = false)
    }, 200)
  } catch (error) {
    console.error('Failed to copy to clipboard', error)
  }
}, 200)

const handleScroll = debounce(() => {
  const scrollThreshold = 400
  const scrollY = window.scrollY || document.documentElement.scrollTop

  if (scrollY > scrollThreshold) {
    isAgentScoped.value = true
  } else {
    isAgentScoped.value = false
  }
  handleScrollPagination()
}, 100)

const handleScrollPagination = () => {
  const scrollThreshold = 650
  const scrollY = window.scrollY || document.documentElement.scrollTop
  const windowHeight = window.innerHeight
  const documentHeight = Math.max(
    document.body.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
  )
  if (documentHeight - (scrollY + windowHeight) < scrollThreshold) {
    PStore.fetchTableDataPagination(route.params.id)
  }
}

onMounted(() => {
  userStore.setScreenSizeInCSS()
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style lang="scss">
@import '../assets/styles/global';

.mobil-info {
  display: none;
}
.presentation {
  &__wrapper {
    position: relative;
    overflow-x: hidden;
  }
  &__header {
    height: 264px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    color: var(--White, #fff);
    font-style: normal;
    padding-left: 48px;
    padding-bottom: 32px;
    padding-right: 48px;
    position: relative;
    top: 0;
    &-map {
      position: absolute;
      height: 264px;
      width: 100vw;
      top: 0;
      left: 0;
      z-index: -1;
    }
    &-title {
      display: grid;
      height: fit-content;
      position: relative;
      z-index: 999;
      h3 {
        font-size: 28px;
        font-weight: 500;
        line-height: 34px;
        margin: 0;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        margin: 0;
      }
    }
    &-nav {
      display: flex;
      align-items: flex-end;
      gap: 8px;
      .link-butt {
        display: flex;
        width: 40px;
        height: 40px;
        padding: 10px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 12px;
        border: 1px solid rgba(255, 255, 255, 0.15);
        background: rgba(255, 255, 255, 0.15);
        color: var(--White, #fff);
        cursor: pointer;
        z-index: 0;
        &:hover {
          background: rgba(255, 255, 255, 0.2);
        }
      }
      .switch-butt {
        display: inline-flex;
        padding: 9px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        background: var(--violet-400, #6b50b2);
        color: var(--White, #fff);
        border: none;
        cursor: pointer;
      }
    }
    .agent-shadow {
      width: 378px;
      margin-right: 48px;
      height: 10px;
      min-width: 378px;
    }
    .title-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
  }
  &__footer {
    position: relative;
    bottom: 0;
    display: inline-flex;
    padding: 9px 48px;
    justify-content: space-between;
    align-items: flex-start;
    height: 40px;
    width: 100%;
    border-top: 1px solid var(--slate-200, #e9e9f3);
    background: var(--White, #fff);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    p {
      color: var(--slate-400, #aeacbf);
      line-height: 20px;
      margin: 0;
      cursor: default;
    }
    &-butts {
      display: flex;
      gap: 24px;
      button {
        border: none;
        background: transparent;
        display: flex;
        align-items: center;
        gap: 8px;
        color: var(--violet-400, #6b50b2);
        line-height: 22px;
        margin: 0;
        cursor: pointer;
      }
    }
  }
}
.modil-switch-butt {
  display: none;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: none;
  background: var(--White, #fff);
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 999;
  box-shadow: 0px 2px 8px 0px rgba(29, 29, 31, 0.1);
  color: var(--Slate-800, #2c2933);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.info-menu {
  &-dropdown {
    border-radius: 12px;
    background: var(--White, #fff);
    box-shadow: 0px 2px 8px 0px rgba(29, 29, 31, 0.1);
    button {
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      gap: 8px;
      color: var(--violet-400, #6b50b2);
      line-height: 22px;
      margin: 0;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 200px) and (max-width: 767px) {
  .presentation {
    &__wrapper {
      .regular-info {
        display: none;
      }
      .mobil-info {
        display: block;
      }
    }

    &__similar {
      &-wrapper {
        max-width: 100vw;
        height: 650px;
        border-radius: 0;
        margin-bottom: 0;
        padding: 0;
        .title {
          margin-left: 16px;
        }
        .swiper {
          .swiper-pagination-fraction,
          .swiper-pagination-custom,
          .swiper-horizontal > .swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal {
            top: unset;
            bottom: -4px;
            position: relative;
          }
          .presentation__table-box {
            width: 360px;
            border: 1px solid var(--Slate-200, #e9e9f3);
            .img-container {
              margin: auto;
            }
          }
        }
      }
    }
    &__table {
      padding: 0;
      max-width: 100vw;
      &-box {
        &-text {
          .subtype,
          .address {
            font-size: 16px;
          }
        }
        &::after {
          display: none;
        }
      }
    }

    &__footer {
      display: none;
    }
    &__header {
      height: 120px;
      .agent-shadow {
        display: none;
      }
      &-title {
        display: none;
      }
      &-map {
        height: 120px;
        .map-wrapper {
          max-width: 100vw;
        }
      }
      &-nav {
        display: none;
      }
    }
  }
  .modil-switch-butt {
    display: inline-flex;
  }
}
@media screen and (min-width: 768px) and (max-width: 1279px) {
  .presentation {
    &__agent {
      &-conteiner {
        .avatar-conteiner {
          .info-menu {
            display: none;
          }
        }
      }
    }
    &__wrapper {
      .regular-info {
        display: none;
      }
      .mobil-info {
        display: block;
      }
    }

    &__similar {
      &-wrapper {
        max-width: 100vw;
        height: 650px;
        border-radius: 0;
        margin-bottom: 0;
        padding: 0;
        .title {
          margin-left: 16px;
        }
        .swiper {
          .swiper-pagination-fraction,
          .swiper-pagination-custom,
          .swiper-horizontal > .swiper-pagination-bullets,
          .swiper-pagination-bullets.swiper-pagination-horizontal {
            top: unset;
            bottom: -4px;
            position: relative;
          }
          .presentation__table-box {
            width: 360px;
            border: 1px solid var(--Slate-200, #e9e9f3);
            .img-container {
              margin: auto;
            }
          }
        }
      }
    }
    &__table {
      padding: 0;
      max-width: 100vw;
    }

    &__header {
      height: 160px;
      padding-right: 15px;
      .title-wrapper {
        justify-content: flex-end;
      }

      &-title {
        display: none;
      }
      &-map {
        height: 160px;
        .map-wrapper {
          max-width: 100vw;
        }
      }
    }
  }
  .modil-switch-butt {
    display: none;
  }
}
</style>
